<template>
  <section>
    <div class="bg-white rounded-md shadow-md p-4">
      <div>
        <p>Aqui va el mapa</p>
      </div>
    </div>
  </section>
</template>
<script>
  import { onMounted, ref } from 'vue'
  export default {
    name: 'mapaRelacionesOv',
    setup () {
      // Referencias
      const show = ref(true)
      onMounted(() => {

      })
      return {
        show
      }
    }
  }
</script>
